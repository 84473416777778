import { createStyles, Container, Anchor, Text, rem } from '@mantine/core'

const useStyles = createStyles((theme) => ({
  footer: {
    marginTop: rem(120),
    borderTop: `1px solid ${theme.colors.gray[2]}`,
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    textAlign: 'center'
  },
  inner: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column'
    }
  },
  link: {
    color: theme.colors.gray[8],
    fontSize: theme.fontSizes.sm
  },
  text: {
    color: theme.colors.gray[6],
    fontSize: theme.fontSizes.sm,
    [theme.fn.smallerThan('xs')]: {
      marginTop: theme.spacing.md
    }
  }
}))

export function Footer() {
  const { classes } = useStyles()

  return (
    <footer className={classes.footer}>
      <Container className={classes.inner}>
        <Text className={classes.text}>{`SWAP Integration LLC, ${new Date().getFullYear()}`}</Text>
        <Text className={classes.text}>
          Learn more about how we handle your data by reviewing our{' '}
          <Anchor<'a'>
            href='https://www.swapintegration.com/privacy'
            target='_blank'
            className={classes.link}
          >
            Privacy Policy
          </Anchor>{' '}
          and{' '}
          <Anchor<'a'>
            href='https://www.swapintegration.com/termsofservice'
            target='_blank'
            className={classes.link}
          >
            Terms of Service
          </Anchor>
          .
        </Text>
      </Container>
    </footer>
  )
}
