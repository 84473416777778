import {
  Box,
  Center,
  Group,
  PasswordInput as MantinePasswordInput,
  type PasswordInputProps as MantinePasswordInputProps,
  Popover,
  Progress,
  Text
} from '@mantine/core'
import { IconCheck, IconX } from '@tabler/icons-react'
import { fill, isEmpty, map, reduce } from 'lodash'
import { type FC, useState } from 'react'

export interface PasswordRequirement {
  pattern: RegExp
  label: string
}
export type PasswordRequirements = PasswordRequirement[]

const defaultRequirments: PasswordRequirements = [
  { pattern: /.{6}/, label: 'At least six characters' },
  { pattern: /\d/, label: 'Includes number' },
  { pattern: /[a-z]/, label: 'Includes lowercase letter' },
  { pattern: /[A-Z]/, label: 'Includes uppercase letter' },
  { pattern: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Includes special symbol' }
]

export interface PasswordInputProps extends MantinePasswordInputProps {
  requirements?: PasswordRequirements | null
  value: string
}

export const PasswordInput: FC<PasswordInputProps> = ({
  requirements = defaultRequirments,
  value,
  ...passwordInputProps
}) => {
  const [popoverOpened, setPopoverOpened] = useState(false)
  const input = (
    <MantinePasswordInput
      mb='xs'
      value={value}
      {...passwordInputProps}
    />
  )

  const progressSections = requirements && fill(new Array(requirements.length), 0)
  const strength = !isEmpty(requirements)
    ? Math.max(
        100 -
          (100 / (requirements.length + 1)) *
            reduce(requirements, (acc, requirement) => (!requirement.pattern.test(value) ? acc + 1 : acc), 0),
        10
      )
    : null

  return requirements ? (
    <Popover
      opened={popoverOpened}
      position='bottom'
      width='target'
      withinPortal
    >
      <Popover.Target>
        <div
          onFocusCapture={() => setPopoverOpened(true)}
          onBlurCapture={() => setPopoverOpened(false)}
        >
          {input}
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <Group
          spacing={5}
          grow
          mb='xs'
        >
          {map(progressSections, (_, idx) => (
            <Progress
              styles={{ bar: { transitionDuration: '0ms' } }}
              value={
                value.length > 0 && idx === 0 ? 100 : strength >= ((idx + 1) / requirements.length) * 100 ? 100 : 0
              }
              color={strength > 80 ? 'teal' : strength > 50 ? 'yellow' : 'red'}
              key={`strengthBar-${idx}`}
              size={4}
            />
          ))}
        </Group>
        {map(requirements, (requirement, idx) => {
          const meets = requirement.pattern.test(value)

          return (
            <Text
              color={meets ? 'teal' : 'red'}
              key={`requirement-${idx}`}
              mt='xs'
              size='sm'
            >
              <Center inline>
                {meets ? (
                  <IconCheck
                    size={14}
                    stroke={1.5}
                  />
                ) : (
                  <IconX
                    size={14}
                    stroke={1.5}
                  />
                )}
                <Box ml={7}>{requirement.label}</Box>
              </Center>
            </Text>
          )
        })}
      </Popover.Dropdown>
    </Popover>
  ) : (
    input
  )
}
