import { Card, Image } from '@mantine/core'
import { type FC } from 'react'

import { CardLayout, type CardLayoutProps } from './CardLayout'

export interface AuthLayoutProps extends CardLayoutProps {}

export const AuthLayout: FC<AuthLayoutProps> = ({ children, ...props }) => (
  <CardLayout {...props}>
    <Card.Section
      mb='sm'
      p='lg'
    >
      <Image
        alt='SWAP PM'
        src='/logo.svg'
        width='100%'
      />
    </Card.Section>
    {children}
  </CardLayout>
)
