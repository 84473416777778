import { Footer } from '@app/components/Footer'
import { Card, type CardProps, Center, LoadingOverlay } from '@mantine/core'
import Head from 'next/head'
import { type FC } from 'react'

export interface CardLayoutProps extends CardProps {
  loading?: boolean
  title: string
}

export const CardLayout: FC<CardLayoutProps> = ({ children, loading, title, ...props }) => (
  <Center
    h='100vh'
    pos='relative'
    w='100vw'
  >
    <LoadingOverlay visible={loading} />
    <Head>
      <title>{`Swap PM / ${title}`}</title>
    </Head>
    <Card
      p='lg'
      radius='md'
      shadow='md'
      w={350}
      withBorder
      {...props}
    >
      {children}
    </Card>
    <Footer />
  </Center>
)
