import { Button, Group, LoadingOverlay } from '@mantine/core'
import { IconCheck } from '@tabler/icons-react'
import { type FC, type FormHTMLAttributes, type ReactNode } from 'react'

export interface FormProps extends FormHTMLAttributes<HTMLFormElement> {
  buttons?: ReactNode | boolean
  isSaving?: boolean
  isValid?: boolean
  submitLabel?: string
}

export const Form: FC<FormProps> = ({
  buttons = true,
  children,
  isSaving,
  isValid = true,
  onSubmit,
  style,
  submitLabel = 'Save',
  ...formProps
}) => (
  <form
    {...formProps}
    onSubmit={onSubmit}
    style={{ position: 'relative', ...style }}
  >
    <LoadingOverlay visible={isSaving} />
    {children}
    {buttons ? (
      <Group position='right'>
        {buttons === true ? (
          <Button
            color='green'
            data-autofocus
            disabled={isSaving || !isValid}
            leftIcon={<IconCheck size={18} />}
            loading={isSaving}
            type='submit'
          >
            {submitLabel}
          </Button>
        ) : (
          buttons
        )}
      </Group>
    ) : null}
  </form>
)
